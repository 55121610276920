body {
    background-color: #fbfbfb;
  }
  
  @media (min-width: 992px) {
    .sidebar {
      height: unset!important;
    }
  }
  .image1 {
    margin-top: 6rem !important;
    margin-right: 5rem !important;
    margin-left: 10rem !important;
  }
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0;
    /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 340px;
    /* z-index: 600; */
  }
  
  .sidebar.show {
    height: 100vh;
  }
  
  @media (max-width: 992px) {
    .sidebar {
      width: 100%;
    }
  }
  
  .sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
  }